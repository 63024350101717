<template>
  <BaseLayout>
    <MainHeader></MainHeader>
    <div class="modal fade region_list">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <h3 style="text-align: center">Select State</h3>
            <div class="list-group">
              <a href="javascript:;" class="list-group-item list-group-item-action" aria-current="true" @click="openMapForCurrentState(state);" v-for="state in Object.values(current_states)">
                {{ state.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade current_location">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <h3 style="text-align: center">MSM would like to access your current location</h3>
            <button class="btn btn-outline-light" @click="getCurrentLocation()">Always Allow</button>
            <button class="btn btn-outline-light" @click="getCurrentLocation()">Always Once</button>
            <button class="btn btn-outline-light" @click="showCategories()">Don't Allow</button>
          </div>
        </div>
      </div>
    </div>
    <div id="select-prompt">
      <p>Click on your location choice and then click "Submit" →</p>
    </div>
    <div class="horses-for-sale-header">
      <div class="text-center text-white" v-if="show_categories === false">
        <h1>FIND TACK, CLOTHES, BOOTS AND EQUIPMENT</h1>
        <div style="margin-top: 40px;" v-if="buttons_enabled">
          <h3 style="margin-bottom: 20px;">Search By:</h3>
          <div class="buttons d-flex justify-content-center">
            <a href="javascript:;" class="main-link" style="margin-right: 20px;" @click="openCurrentLocation();">Current Location</a>
            <a href="javascript:;" class="main-link" style="margin-right: 20px;" @click="showAddressSearch();">City, State or Zip Code</a>
            <a href="javascript:;" class="main-link" style="margin-right: 20px;" @click="showRegions();">Region</a>
            <a href="javascript:;" class="main-link" @click="showCategories()">All Locations</a>
          </div>
        </div>
        <div class="address-search-box" style="margin-top: 40px;" v-if="address_search_enabled">
          <h3 style="margin-bottom: 20px;">Start typing and then click on your choice in the drop down list. Then click "Submit"</h3>
          <div class="d-flex justify-content-center">
            <input type="text" id="autocomplete_search" style="width: 400px; border: 1px solid #fff; padding: 20px; background: rgb(0, 0, 0, 0.5); color: #fff;" :disabled="mapAutoComplete === null" :placeholder="mapAutoComplete === null ? 'Loading...' : 'City, state or zip code...'" />
            <button class="btn btn-primary" @click="openMapForSelectedPlace" style="margin-left: 10px; padding: 0 20px;">Submit</button>
          </div>
        </div>
        <div class="region-box" style="margin-top: 40px;" v-if="regions_enabled">
          <h3 style="margin-bottom: 20px;">Choose Selected Region</h3>
          <div style="width: 900px; margin: 0 auto;">
            <a href="javascript:;" class="main-link" style="margin-right: 20px; width: 200px; display: inline-block; margin-bottom: 20px;" @click="showStatesFor('northeast');" data-toggle="tooltip" data-placement="top" :title="listStatesFor('northeast')" data-html="true">Northeast</a>
            <a href="javascript:;" class="main-link" style="margin-right: 20px; width: 200px; display: inline-block; margin-bottom: 20px;" @click="showStatesFor('southeast');" data-toggle="tooltip" data-placement="top" :title="listStatesFor('southeast')" data-html="true">Southeast</a>
            <a href="javascript:;" class="main-link" style="margin-right: 20px; width: 200px; display: inline-block; margin-bottom: 20px;" @click="showStatesFor('mountain');" data-toggle="tooltip" data-placement="top" :title="listStatesFor('mountain')" data-html="true">Mountain</a>
            <a href="javascript:;" class="main-link" style="margin-right: 20px; width: 200px; display: inline-block; margin-bottom: 20px;" @click="showStatesFor('pacific');" data-toggle="tooltip" data-placement="top" :title="listStatesFor('pacific')" data-html="true">Pacific</a>
            <a href="javascript:;" class="main-link" style="margin-right: 20px; width: 200px; display: inline-block;" @click="showStatesFor('central');" data-toggle="tooltip" data-placement="top" :title="listStatesFor('central')" data-html="true">Central</a>
          </div>
        </div>
        <div class="bottom-button" style="margin-top: 140px;">
          <a href="javascript:;" class="main-link active" v-if="$store.state.token === null" @click="openRegisterModal('/save-equipment')">List Tack, Clothes, Boots and Equipment</a>
          <router-link to="/save-equipment" class="main-link active" v-if="$store.state.token !== null">List Tack, Clothes, Boots and Equipment</router-link>
        </div>
      </div>
      <div class="text-center text-white" v-if="show_categories">
        <h1>Select an Equipment Type</h1>
        <div id="categories" style="margin-top: 40px;">
          <select autocomplete="off" id="provider-type-select" class="form-control" @change="openSelectedLocation($event.target.value)" style="margin:0 auto; width: 500px;">
            <option value="" disabled selected>Select Equipment Type Here</option>
            <option value="Any">All</option>
            <optgroup label="Horse">
              <option :value="item" v-for="item in horse_equipment_types" :key="item">{{item}}</option>
            </optgroup>
            <optgroup label="People">
              <option :value="item" v-for="item in people_equipment_types" :key="item">{{item}}</option>
            </optgroup>
          </select>
        </div>
      </div>
    </div>
    <div>
      <div class="container">
        <h2 class="blue-top-border" style="text-align: center; margin: 40px 0;"><span></span>How It Works</h2>
        <div class="row">
          <div class="img-container col-lg-6 col-md-12">
            <img class="styled-steps-image" src="../assets/img/equipment_how_it_works_step1.jpg" width="500" />
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="d-flex justify-content-start">
              <img src="../assets/img/horse_for_sale_step1_icon.png" width="80" height="80" />
              <div style="margin-left: 20px; margin-top: 20px;">
                <h3>Step 1</h3>
                <p>Choose method to search by – Current Location, City State Zip Code, Region or All.  </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="img-container col-lg-6 col-md-12">
            <img class="styled-steps-image" src="../assets/img/equipment_how_it_works_step2.jpg" width="500" />
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="d-flex justify-content-start">
              <img src="../assets/img/horse_for_sale_step2_icon.png" width="80" height="80" />
              <div style="margin-left: 20px; margin-top: 20px;">
                <h3>Step 2</h3>
                <p>Select an Equipment Type for which are searching.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="img-container col-lg-6 col-md-12">
            <img class="styled-steps-image" src="../assets/img/service_how_it_works_step3.png" width="500" />
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="d-flex justify-content-start">
              <img src="../assets/img/horse_for_sale_step3_icon.png" width="80" height="80" />
              <div style="margin-left: 20px; margin-top: 20px;">
                <h3>Step 3</h3>
                <p>Browse Listings or select a different equipment type from the drop down list.</p>
                <p>Click on a map pin or click on “List View” to see details about the equipment.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="img-container col-lg-6 col-md-12">
            <img class="styled-steps-image" src="../assets/img/equipment_how_it_works_step4.jpg" width="500" />
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="d-flex justify-content-start">
              <img src="../assets/img/horse_for_sale_step3_icon.png" width="80" height="80" />
              <div style="margin-left: 20px; margin-top: 20px;">
                <h3>Step 4</h3>
                <p>Save searches and share searches by creating an account or signing into an existing account.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import {regions} from '@/constants/states';
import BaseLayout from '@/layouts/Base.vue';
import MainHeader from "@/components/MainHeader.vue";
import {Loader} from "@googlemaps/js-api-loader";
import $ from 'jquery';
import 'bootstrap/js/src/tooltip';
import axios from "axios";
import Constants from '@/constants/constants';
import HorseEquipmentTypes from '@/constants/horse_equipment_types';
import PeopleEquipmentTypes from '@/constants/people_equipment_types';

export default {
  components: {
    MainHeader,
    BaseLayout
  },
  data() {
    return {
      show_categories: false,
      selected_location: null,
      buttons_enabled: true,
      address_search_enabled: false,
      regions_enabled: false,
      mapAutoComplete: null,
      mapAutoCompleteListener: null,
      google_maps_loader: null,
      current_states: {},
      selected_state: '',
      selected_place: null,
      selected_region: null,
      regions: regions,
      clearSelectInterval: null,
      horse_equipment_types: HorseEquipmentTypes,
      people_equipment_types: PeopleEquipmentTypes,
    }
  },
  async mounted() {
    document.title = 'Equipment - Mane Street Market';
    window.scrollTo(0, 0);
    this.clearSelectInterval = setInterval(() => {
      if (document.getElementById('provider-type-select') != null) {
        document.getElementById('provider-type-select').selectedIndex = 0; // reset selected for when user navigates back
        clearInterval(this.clearSelectInterval);
      }
    }, 500);
  },
  unmounted() {
    if (this.address_search_enabled) {
      window.google.maps.event.removeListener(this.mapAutoCompleteListener);
      window.google.maps.event.clearInstanceListeners(this.mapAutoComplete);
      this.google_maps_loader.deleteScript();
    }
  },
  methods: {
    async showAddressSearch() {
      this.buttons_enabled = false;
      this.address_search_enabled = true;
      this.google_maps_loader = new Loader({
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        libraries: ['places'],
        version: Constants.GOOGLE_MAPS_VERSION
      });
      await this.google_maps_loader.load();
      this.mapAutoComplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete_search'));
      // this.mapAutoComplete.setComponentRestrictions({
      //   country: ["us", "ca", "ie", "uk"],
      // });
      this.mapAutoCompleteListener = window.google.maps.event.addListener(this.mapAutoComplete, 'place_changed', this.searchOnMap);

      $('#autocomplete_search').on('input', () => {
        if (this.selected_place == null) {
          $('.horses-for-sale-header').css('filter', 'brightness(0.5)');
          $('#select-prompt').css('opacity', '1').css('z-index', '99');
        }
      });
    },
    listStatesFor(region) {
      let html = [];
      for (let state of Object.values(this.regions[region])) {
        html.push(`<span>${state.name}</span>`);
      }
      return `${html.join(', ')}`;
    },
    showRegions() {
      this.buttons_enabled = false;
      this.regions_enabled = true;
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
      }, 500);
    },
    searchOnMap() {
      $('.horses-for-sale-header').css('filter', 'none');
      $('#select-prompt').css('opacity', '0').css('z-index', '-1');
      this.selected_place = this.mapAutoComplete.getPlace();
    },
    openMapForCurrentState(state) {
      $('.region_list').modal('hide');
      this.selected_location = {lat: state.lat, lng: state.lng};
      this.showCategories();
    },
    showStatesFor(region) {
      $('[data-toggle="tooltip"]').tooltip('hide');
      this.selected_region = region;
      this.showCategories();
    },
    openMapForSelectedPlace() {
      this.selected_location = {lat: this.selected_place.geometry['location'].lat(), lng: this.selected_place.geometry['location'].lng()};
      this.showCategories();
    },
    openCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.geoSuccess, (err) => {
          console.log(err);
          this.showCategories();
        });
      } else {
        this.showCategories();
      }
    },
    geoSuccess(position) {
      this.selected_location = {lat: position.coords.latitude, lng: position.coords.longitude};
      this.showCategories();
    },
    openSelectedLocation(type) {
      let query = {type: type};
      if (this.selected_location !== null) {
        query.lat = this.selected_location.lat;
        query.lng = this.selected_location.lng;
        query.zoom = 7;
      }
      if (this.selected_region !== null) {
        let states = Object.values(this.regions[this.selected_region]);
        query.lat = states[3].lat;
        query.lng = states[3].lng;
        query.zoom = 5;
        query.region = this.selected_region;
      }
      this.$router.push({path: '/search-equipment', query: query});
    },
    showCategories() {
      this.show_categories = true;
    },
    openRegisterModal(redirect) {
      $('#loginModal').modal('show');
      $('#myTab a[href="#create-account-content"]').tab('show')
      if (redirect != '') {
        $('#myTab input[name=redirect]').val(redirect);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horses-for-sale-header {
  background: linear-gradient(270deg, #15249D99 0%, #0011A08F 44%, #04117B 100%, #132093 100%), url("../assets/img/service_providers_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding: 64px 0;

  .main-link {
    padding: 20px 40px;
    border: 1px solid #fff;
    text-decoration: none;
    color: #fff;

    &:hover, &.active {
      background: #fff;
      color: #000;
    }
  }

  @media only screen and (max-width: 1000px) {
    h1 {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.address-search-box {
  @media only screen and (max-width: 1000px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.region-box {
  @media only screen and (max-width: 1000px) {
    margin-left: 20px;
    margin-right: 20px;

    div {
      width: 350px !important;

      a {
        display: block;
        margin-bottom: 5px !important;
        padding: 10px !important;
      }
    }
  }
}

.bottom-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    margin-top: 50px !important;
  }
}

.buttons {
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;

    .main-link {
      width: 300px;
      padding: 10px 0;
      margin-right: 0px !important;
      margin-bottom: 10px;
    }
  }
}

.row {
  row-gap: 16px;
  margin-bottom: 16px;
}

.img-container {
  @media only screen and (max-width: 1000px) {
    text-align: center;

    img {
      width: 380px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

#categories {
  @media only screen and (max-width: 1000px) {
    select{
      width: 300px !important;
    }
  }
}

.styled-steps-image {
  border-radius: 32px;
  margin: 12px;
  width: 480px;
  box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.1);
}

#select-prompt {
  position: absolute;
  top: 420px;
  color: white;
  margin-left: 10vw;
  margin-right: calc(35vw + 500px);
  text-align: right;
  opacity: 0;
  z-index: -1;
}

@media only screen and (max-width: 768px) {
  #select-prompt {
    position: absolute;
    top: 360px;
    color: white;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: center;
    opacity: 0;
    z-index: -1;
    background: black;
    padding: 0.6rem;

    p {
      margin: 0;
    }
  }
}
</style>